<script setup>
import { NodeViewWrapper } from '@tiptap/vue-3';
import CustomfieldAddOrEditAdvancedFormulaChip from './CustomfieldAddOrEditAdvancedFormulaChip.vue';

const props = defineProps({
  node: {
    type: Object,
    required: true,
  },
  getPos: {
    type: Function,
    required: true,
  },
});

function updateChip(event) {
  const pos = props.getPos();
  const customEvent = new CustomEvent('formula-chip-click', {
    detail: {
      pos,
      node: props.node.toJSON(),
    },
    bubbles: true,
  });
  event.currentTarget.dispatchEvent(customEvent);
}
</script>

<template>
  <NodeViewWrapper>
    <CustomfieldAddOrEditAdvancedFormulaChip
      :value="node.attrs.value"
      :icon="node.attrs.icon"
      :type="node.attrs.type || 'field'"
      :canEdit="true"
      @click="updateChip"
    />
  </NodeViewWrapper>
</template>
