<script setup>
import { useI18n } from '@/util';
import { useFormulaData } from './useFormulaData.js';
import { insertFormulaChip } from './utils';

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  editor: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: 'md',
  },
});

const emit = defineEmits(['showOption']);

const { t } = useI18n();
const { formulaFunctions, formulaOperators } = useFormulaData();
const size = computed(() => (props.size === 'md' ? 'w-48' : 'w-60'));
const functions = formulaFunctions.map(({ name, description, syntax }) => ({
  name,
  description,
  syntax,
  type: 'function',
}));
const operators = formulaOperators.map(({ name, description, operator, icon, syntax }) => ({
  name,
  description,
  operator,
  icon,
  syntax,
  type: 'operator',
}));

function insertChip(item) {
  if (!props.editor) return;

  // For options panel examples, always insert at doc end
  insertFormulaChip(props.editor, item, {
    insertAtEnd: true,
    shouldFocus: true,
    fromOptionsPanel: true,
  });
}

function insertOperator(operator) {
  if (!props.editor) return;

  props.editor.chain().insertContent(` ${operator.operator} `).run();
  props.editor.commands.focus();
}

const hoveredItem = shallowRef(null);
const isFunctionsExpanded = shallowRef(false);
const isFieldsExpanded = shallowRef(false);
const isOperatorsExpanded = shallowRef(false);

function toggleSection(section) {
  switch (section) {
    case 'functions':
      isFunctionsExpanded.value = !isFunctionsExpanded.value;
      break;
    case 'fields':
      isFieldsExpanded.value = !isFieldsExpanded.value;
      break;
    case 'operators':
      isOperatorsExpanded.value = !isOperatorsExpanded.value;
      break;
    default:
      break;
  }
}

function hoverItem(item) {
  hoveredItem.value = item;
  emit('showOption', item);
}
</script>

<template>
  <div class="flex h-96 flex-col border-x border-separator" :class="size">
    <div class="flex-1 overflow-hidden">
      <div class="h-full overflow-y-auto">
        <div class="space-y-1">
          <div>
            <div
              class="sticky top-0 z-10 flex h-8 cursor-pointer items-center justify-between bg-default px-4"
              @click="toggleSection('functions')"
            >
              <div class="flex items-center gap-3">
                <h3 class="select-none text-body-1 font-semibold">{{ t('Functions') }}</h3>
              </div>
              <LscIcon
                icon="lsi-dropdown"
                size="md"
                class="transition-transform"
                :class="{ 'rotate-180 transform': isFunctionsExpanded }"
              />
            </div>

            <div v-show="isFunctionsExpanded" class="bg-default">
              <div class="px-4 py-1">
                <button
                  v-for="func in functions"
                  :key="func.name"
                  type="button"
                  class="group flex w-full min-w-0 items-center rounded-md text-left text-body-2 hover:bg-hover focus-visible:bg-hover"
                  :class="{ 'bg-hover font-semibold': hoveredItem === func }"
                  @click="insertChip(func)"
                  @mouseenter="hoverItem(func)"
                >
                  <LscIcon icon="lsi-formula-function" size="sm" class="m-2 text-icon-subtle" />
                  <LscOverflowEllipsis class="flex-1">
                    {{ func.name }}
                  </LscOverflowEllipsis>
                  <LscIconButton
                    v-if="hoveredItem === func"
                    v-LsdTooltip="t('Add operator')"
                    class="m-2 opacity-0 group-hover:opacity-100"
                    icon="lsi-add"
                    size="xs"
                  />
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              class="sticky top-0 z-10 flex h-8 cursor-pointer items-center justify-between bg-default px-4"
              @click="toggleSection('fields')"
            >
              <div class="flex items-center gap-3">
                <h3 class="select-none text-body-1 font-semibold">{{ t('Fields') }}</h3>
              </div>
              <LscIcon
                icon="lsi-dropdown"
                size="md"
                class="transition-transform"
                :class="{ 'rotate-180 transform': isFieldsExpanded }"
              />
            </div>

            <div v-show="isFieldsExpanded" class="bg-default">
              <div class="px-4 py-1">
                <button
                  v-for="field in fields"
                  :key="field.name"
                  type="button"
                  class="group flex w-full min-w-0 items-center rounded-md text-left text-body-2 hover:bg-hover focus-visible:bg-hover"
                  :class="{ 'bg-hover font-semibold': hoveredItem === field }"
                  @click="insertChip(field)"
                  @mouseenter="hoverItem(field)"
                >
                  <LscIcon :icon="field.icon" size="sm" class="m-2 text-icon-subtle" />
                  <LscOverflowEllipsis class="flex-1">
                    {{ field.name }}
                  </LscOverflowEllipsis>
                  <LscIconButton
                    v-if="hoveredItem === field"
                    v-LsdTooltip="t('Add field')"
                    class="m-2 opacity-0 group-hover:opacity-100"
                    icon="lsi-add"
                    size="xs"
                  />
                </button>
              </div>
            </div>
          </div>

          <div>
            <div
              class="sticky top-0 z-10 flex h-8 cursor-pointer items-center justify-between bg-default px-4"
              @click="toggleSection('operators')"
            >
              <div class="flex items-center gap-3">
                <h3 class="select-none text-body-1 font-semibold">{{ t('Operators') }}</h3>
              </div>
              <LscIcon
                icon="lsi-dropdown"
                size="md"
                class="transition-transform"
                :class="{ 'rotate-180 transform': isOperatorsExpanded }"
              />
            </div>

            <div v-show="isOperatorsExpanded" class="bg-default">
              <div class="px-4 py-1">
                <button
                  v-for="operator in operators"
                  :key="operator.name"
                  type="button"
                  class="group flex w-full min-w-0 items-center rounded-md text-left text-body-2 hover:bg-hover focus-visible:bg-hover"
                  :class="{ 'bg-hover font-semibold': hoveredItem === operator }"
                  @click="insertOperator(operator)"
                  @mouseenter="hoverItem(operator)"
                >
                  <LscIcon :icon="operator.icon" size="sm" class="m-2 text-icon-subtle" />
                  <LscOverflowEllipsis class="flex-1">
                    {{ operator.name }}
                  </LscOverflowEllipsis>
                  <LscIconButton
                    v-if="hoveredItem === operator"
                    v-LsdTooltip="t('Add operator')"
                    class="m-2 opacity-0 group-hover:opacity-100"
                    icon="lsi-add"
                    size="xs"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex shrink-0 items-center gap-2 border-t p-4 hover:cursor-pointer" @mouseenter="hoverItem(null)">
      <LscIcon icon="lsi-help" size="sm" class="text-icon-subtle" />
      <span class="text-body-2">{{ t('How to start?') }}</span>
    </div>
  </div>
</template>
