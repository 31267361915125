<script setup>
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import { EditorContent, useEditor } from '@tiptap/vue-3';
import { useCustomfieldsV3Loader } from '@/api';
import { useI18n } from '@/util';
import { useReportCustomBuilderSettingsColumns } from '@/module/report';
import { customfieldTypes } from '../../constants';
import { createCustomfieldFormatter } from '../customfieldFormatter';
import { useFormulaData } from './useFormulaData.js';
import {
  customfieldAdvancedFormulaChipExtension,
  FormulaAutocompleteExtension,
  insertFormulaChip,
  OperatorDecorationExtension,
  parseFormulaStringToTiptapJson,
} from './utils';

const props = defineProps({
  existingFormulaString: {
    type: String,
    default: '',
  },
  entity: {
    type: String,
    required: true,
  },
});

const { t } = useI18n();
const customfieldFormatter = createCustomfieldFormatter(t);
const { formulaFunctions, formulaOperators } = useFormulaData();

const functions = formulaFunctions.map(({ name }) => ({
  name,
  type: 'function',
}));

const entityWithoutPrefix = computed(() => props.entity.replace('cr-', ''));
const settings = useReportCustomBuilderSettingsColumns(entityWithoutPrefix);
const columns = computed(() => settings.value?.filter((item) => item.props.type === 'number') ?? []);

const { items: customfields } = useCustomfieldsV3Loader({
  count: Infinity,
  pageSize: 500,
  params: computed(() => ({
    entities: entityWithoutPrefix.value,
    onlySiteLevel: true,
  })),
});

const numericCustomfields = computed(() => {
  return customfields.value
    .filter(
      (customfield) =>
        customfield.type === customfieldTypes.NUMBER_INTEGER || customfield.type === customfieldTypes.NUMBER_DECIMAL,
    )
    .map(customfieldFormatter);
});

const fields = computed(() =>
  columns.value.concat(numericCustomfields.value).map((field) => {
    return {
      ...field,
      name: field.title,
      type: 'field',
      icon: field.props.icon,
    };
  }),
);

// refs needs for reactivity for the menu
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const currentInput = ref('');
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const menuPosition = ref({ pos: 0, node: null });
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const menuSheetRef = ref(null);
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const menuItemRefs = ref([]);
const showMenu = shallowRef(false);
const wasCleared = shallowRef(false);
const lastClearedFormula = shallowRef('');
const menuActivator = shallowRef(null);
const selectedItemIndex = shallowRef(0);

const allMenuItems = computed(() => [...functions, ...fields.value]);

const filteredItems = computed(() => {
  if (!currentInput.value) {
    // When clicking a chip, show all items
    return menuPosition.value.node ? allMenuItems.value : [];
  }

  const searchTerm = currentInput.value.toLowerCase();
  return allMenuItems.value.filter((candidate) => candidate.name.toLowerCase().includes(searchTerm));
});

const initialContent = computed(() => {
  if (props.existingFormulaString) {
    return parseFormulaStringToTiptapJson(props.existingFormulaString, fields.value, formulaFunctions);
  }
  return '';
});

const operatorDecorationExtension = OperatorDecorationExtension.configure({
  formulaOperators: formulaOperators.map((op) => ({
    operator: op.operator,
    name: op.name,
  })),
});

// Configure the formula autocomplete extension
const formulaAutocompleteExtension = FormulaAutocompleteExtension.configure({
  onShowMenu: () => {
    showMenu.value = true;
    selectedItemIndex.value = 0;
  },
  onHideMenu: () => {
    showMenu.value = false;
    currentInput.value = '';
  },
  onUpdateInput: (text) => {
    // Needed for deleting
    if (!currentInput.value) {
      currentInput.value = text;
    } else {
      if (text.length < currentInput.value.length) {
        currentInput.value = text;
      } else {
        currentInput.value += text;
      }
    }
  },
  onGetCaretPosition: (pos) => {
    if (menuActivator.value) {
      menuActivator.value.style.position = 'fixed';
      menuActivator.value.style.top = `${pos.top}px`;
      menuActivator.value.style.left = `${pos.left}px`;
      menuActivator.value.style.width = `${pos.right - pos.left}px`;
      menuActivator.value.style.height = `${pos.bottom - pos.top}px`;
      menuActivator.value.style.visibility = 'visible';
      requestAnimationFrame(() => {
        window.dispatchEvent(new Event('resize'));
      });
    }
  },
});

const editor = useEditor({
  extensions: [
    Document,
    Paragraph,
    Text,
    Placeholder.configure({ placeholder: t('Enter manually or choose from the panel on the left') }),
    customfieldAdvancedFormulaChipExtension.configure({
      HTMLAttributes: {
        class: 'formula-chip',
      },
    }),
    operatorDecorationExtension,
    formulaAutocompleteExtension,
  ],
  content: initialContent.value,
  editable: true,
  autofocus: true,
});

function showAllItemsMenu(event) {
  const { pos, node } = event.detail;

  showMenu.value = true;
  currentInput.value = '';
  menuPosition.value = { pos, node };

  if (menuActivator.value) {
    const chipRect = pos;
    menuActivator.value.style.position = 'fixed';
    menuActivator.value.style.top = `${chipRect.top}px`;
    menuActivator.value.style.left = `${chipRect.left}px`;
    menuActivator.value.style.width = `${chipRect.right - chipRect.left}px`;
    menuActivator.value.style.height = `${chipRect.bottom - chipRect.top}px`;
    menuActivator.value.style.visibility = 'visible';

    selectedItemIndex.value = 0;

    requestAnimationFrame(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }
}

function chooseMenuItem(selectedItem) {
  if (!editor.value) return;

  if (menuPosition.value.node) {
    const { pos, node } = menuPosition.value;

    let chipPos = null;
    editor.value.state.doc.descendants((docNode, docPos) => {
      if (docNode.type.name === 'formulaChip' && docNode.attrs.value === node.attrs.value) {
        chipPos = docPos;
        return false;
      }
      return true;
    });

    if (chipPos !== null) {
      const isFieldToFunction = node.attrs.type === 'field' && selectedItem.type === 'function';

      const { tr } = editor.value.state;
      tr.setNodeMarkup(chipPos, null, {
        type: selectedItem.type,
        value: selectedItem.name,
        icon: selectedItem.type === 'function' ? 'lsi-customfield-formula' : selectedItem.icon || 'lsi-field-default',
      });
      editor.value.view.dispatch(tr);

      if (isFieldToFunction) {
        editor.value
          .chain()
          .setTextSelection(chipPos + 1)
          .run();

        editor.value
          .chain()
          .insertContent([
            { type: 'text', text: '(' },
            { type: 'text', text: ' ' },
            { type: 'text', text: ')' },
            { type: 'text', text: ' ' },
          ])
          .run();

        nextTick(() => {
          editor.value.commands.focus();
          editor.value.commands.setTextSelection(chipPos + 2);
        });
      } else {
        nextTick(() => {
          const focusPos = chipPos + 1;
          editor.value.commands.focus();
          editor.value.commands.setTextSelection(focusPos);
        });
      }
    } else {
      // Fallback
      insertFormulaChip(editor.value, selectedItem, { insertPos: pos });
    }
  } else {
    // removing search term when replacing with chip and fixing focus
    const { from } = editor.value.state.selection;
    const textBefore = editor.value.state.doc.textBetween(0, from).trim();
    const lastWord = textBefore.split(' ').pop() || '';
    let lastWordPos = -1;

    if (lastWord && lastWord === currentInput.value) {
      let wordFound = false;

      editor.value.state.doc.nodesBetween(Math.max(0, from - lastWord.length - 10), from, (node, pos) => {
        if (node.isText && !wordFound) {
          const nodeText = node.text;
          const index = nodeText.lastIndexOf(lastWord);
          if (index !== -1 && pos + index + lastWord.length <= from) {
            lastWordPos = pos + index;
            wordFound = true;
            return false;
          }
        }
        return true;
      });

      if (!wordFound) {
        lastWordPos = from - lastWord.length;
      }
    }

    // removing search term when replacing with chip
    if (lastWordPos >= 0) {
      editor.value
        .chain()
        .setTextSelection({ from: lastWordPos, to: lastWordPos + lastWord.length })
        .deleteRange({ from: lastWordPos, to: lastWordPos + lastWord.length })
        .run();

      insertFormulaChip(editor.value, selectedItem, {
        insertPos: lastWordPos,
        shouldFocus: true,
      });
    } else {
      insertFormulaChip(editor.value, selectedItem, {
        searchText: currentInput.value,
        shouldFocus: true,
      });
    }
  }

  showMenu.value = false;
  currentInput.value = '';
  menuPosition.value = { pos: 0, node: null };
}

const formulaString = computed(() => {
  if (!editor.value) return '';

  const json = editor.value.getJSON();
  let formula = '';

  json.content?.[0]?.content?.forEach((node, index) => {
    if (node.type === 'text') {
      formula += node.text;
    } else if (node.type === 'formulaChip') {
      if (index > 0 && !formula.endsWith(' ')) {
        formula += ' ';
      }

      formula += `"${node.attrs.value}"`;

      if (index < json.content[0].content.length - 1) {
        formula += ' ';
      }
    }
  });

  return formula;
});

function clearOrUndo() {
  if (!wasCleared.value) {
    lastClearedFormula.value = formulaString.value;
    editor.value?.commands.clearContent();
    wasCleared.value = true;
  } else {
    const parsed = parseFormulaStringToTiptapJson(lastClearedFormula.value, fields.value, formulaFunctions);
    editor.value?.commands.setContent(parsed);
    wasCleared.value = false;
  }
}

// Handle keyboard navigation
function processKeyDown(event) {
  if (!showMenu.value || filteredItems.value.length === 0) {
    return;
  }

  if (event.key === 'ArrowUp') {
    event.preventDefault();
    selectedItemIndex.value = (selectedItemIndex.value - 1 + filteredItems.value.length) % filteredItems.value.length;
    scrollSelectedItemIntoView();
  } else if (event.key === 'ArrowDown') {
    event.preventDefault();
    selectedItemIndex.value = (selectedItemIndex.value + 1) % filteredItems.value.length;
    scrollSelectedItemIntoView();
  } else if (event.key === 'Enter' || event.key === 'Tab') {
    event.preventDefault();
    if (filteredItems.value[selectedItemIndex.value]) {
      const selectedItem = filteredItems.value[selectedItemIndex.value];

      // When using keyboard navigation, we need to handle focus explicitly
      if (menuPosition.value.node) {
        addFormulaChip(selectedItem);
      } else {
        addFormulaChipFromKeyboard(selectedItem);
      }

      showMenu.value = false;
      currentInput.value = '';
      menuPosition.value = { pos: 0, node: null };
    }
  } else if (event.key === 'Escape') {
    event.preventDefault();
    showMenu.value = false;
  }
}

function updateSelectedElement(item, el) {
  if (el) {
    const index = filteredItems.value.findIndex((i) => i.name === item.name);
    menuItemRefs.value[index] = el;
  }
}

function scrollSelectedItemIntoView() {
  nextTick(() => {
    if (!menuItemRefs.value[selectedItemIndex.value]) return;
    menuItemRefs.value[selectedItemIndex.value].$el.scrollIntoView({ block: 'nearest' });
  });
}

// Helper for updating chip
function addFormulaChip(selectedItem) {
  const { node } = menuPosition.value;

  let chipPos = null;
  editor.value.state.doc.descendants((docNode, docPos) => {
    if (docNode.type.name === 'formulaChip' && docNode.attrs.value === node.attrs.value) {
      chipPos = docPos;
      return false;
    }
    return true;
  });

  if (chipPos !== null) {
    // Check if we're changing from 'field' to 'function' type
    const isFieldToFunction = node.attrs.type === 'field' && selectedItem.type === 'function';

    const { tr } = editor.value.state;
    tr.setNodeMarkup(chipPos, null, {
      type: selectedItem.type,
      value: selectedItem.name,
      icon: selectedItem.type === 'function' ? 'lsi-customfield-formula' : selectedItem.icon || 'lsi-field-default',
    });
    editor.value.view.dispatch(tr);

    if (isFieldToFunction) {
      editor.value
        .chain()
        .setTextSelection(chipPos + 1)
        .run();

      editor.value
        .chain()
        .insertContent([
          { type: 'text', text: '(' },
          { type: 'text', text: ' ' },
          { type: 'text', text: ')' },
          { type: 'text', text: ' ' },
        ])
        .run();

      nextTick(() => {
        editor.value.commands.focus();
        editor.value.commands.setTextSelection(chipPos + 2); // After chip + opening parenthesis
      });
    } else {
      nextTick(() => {
        const focusPos = chipPos + 1;
        editor.value.commands.focus();
        editor.value.commands.setTextSelection(focusPos);
      });
    }
  }
}

// Helper for inserting new chip via keyboard to ensure consistent focus
function addFormulaChipFromKeyboard(selectedItem) {
  const { from } = editor.value.state.selection;
  const textBefore = editor.value.state.doc.textBetween(0, from).trim();
  const lastWord = textBefore.split(' ').pop() || '';
  let lastWordPos = -1;

  if (lastWord && lastWord === currentInput.value) {
    let wordFound = false;

    editor.value.state.doc.nodesBetween(Math.max(0, from - lastWord.length - 10), from, (node, pos) => {
      if (node.isText && !wordFound) {
        const nodeText = node.text;
        const index = nodeText.lastIndexOf(lastWord);
        if (index !== -1 && pos + index + lastWord.length <= from) {
          lastWordPos = pos + index;
          wordFound = true;
          return false;
        }
      }
      return true;
    });

    if (!wordFound) {
      lastWordPos = from - lastWord.length;
    }
  }

  // Delete the search term
  if (lastWordPos >= 0) {
    editor.value
      .chain()
      .setTextSelection({ from: lastWordPos, to: lastWordPos + lastWord.length })
      .deleteRange({ from: lastWordPos, to: lastWordPos + lastWord.length })
      .run();

    const chain = editor.value.chain().setTextSelection(lastWordPos);

    chain
      .insertContent({
        type: 'formulaChip',
        attrs: {
          type: selectedItem.type,
          value: selectedItem.name,
          icon: selectedItem.type === 'function' ? 'lsi-customfield-formula' : selectedItem.icon || 'lsi-field-default',
        },
      })
      .run();

    if (selectedItem.type === 'function') {
      editor.value
        .chain()
        .insertContent([
          { type: 'text', text: '(' },
          { type: 'text', text: ' ' },
          { type: 'text', text: ')' },
          { type: 'text', text: ' ' },
        ])
        .run();
    }

    //  important for focus
    nextTick(() => {
      const pos = lastWordPos + 1;
      const functionOffset = selectedItem.type === 'function' ? 2 : 0;
      editor.value.commands.focus();
      editor.value.commands.setTextSelection(pos + functionOffset);
    });
  } else {
    insertFormulaChip(editor.value, selectedItem, {
      searchText: currentInput.value,
      shouldFocus: true,
    });

    nextTick(() => {
      editor.value.commands.focus();
    });
  }
}

// for the clear and undo
watch(formulaString, (newValue) => {
  if (wasCleared.value && newValue && newValue !== lastClearedFormula.value) {
    wasCleared.value = false;
  }
});

defineExpose({
  editor,
  fields,
  formulaString,
});

// Needed for chip clicks
onMounted(() => {
  document.body.addEventListener('formula-chip-click', showAllItemsMenu);
  menuActivator.value = document.createElement('div');
  menuActivator.value.style.position = 'fixed';
  menuActivator.value.style.visibility = 'hidden';
  document.body.appendChild(menuActivator.value);
  document.addEventListener('keydown', processKeyDown);
  nextTick(() => {
    if (editor.value) {
      editor.value.commands.focus();
    }
  });
});

onUnmounted(() => {
  document.body.removeEventListener('formula-chip-click', showAllItemsMenu);
  document.removeEventListener('keydown', processKeyDown);
  editor.value?.destroy();

  if (menuActivator.value && document.body.contains(menuActivator.value)) {
    document.body.removeChild(menuActivator.value);
  }
});
</script>

<template>
  <div class="relative">
    <div class="rounded-md border-separator bg-surface-default p-3">
      <div class="mb-2 flex items-center gap-2">
        <span class="front-semibold text-body-2 text-subtle">{{ t('Formula') }}</span>
        <LscLabel variant="emphasis" size="sm">{{ t('Beta') }}</LscLabel>
        <LscButton
          v-if="formulaString || wasCleared"
          class="ml-auto"
          variant="plain-secondary"
          size="sm"
          :prependIcon="wasCleared ? 'lsi-undo' : 'lsi-clear-formatting'"
          @click="clearOrUndo"
        >
          {{ wasCleared ? t('Undo') : t('Clear') }}
        </LscButton>
      </div>
      <EditorContent :editor="editor" class="formula-editor prose prose-sm bg-default" />
    </div>

    <LscMenu
      v-model="showMenu"
      :activator="menuActivator"
      position="bottom"
      @update:modelValue="
        (val) => {
          if (!val && editor.value) {
            editor.value.commands.focus();
          }
        }
      "
    >
      <LscSheet ref="menuSheetRef" class="max-h-80 w-fit overflow-y-auto px-1 py-1">
        <div class="flex flex-col">
          <LscOptionsMenuItem
            v-for="(item, index) in filteredItems"
            :key="item.name"
            :ref="(el) => updateSelectedElement(item, el)"
            :text="item.name"
            :prependIcon="item.type === 'function' ? 'lsi-customfield-formula' : item.icon"
            :class="{ 'bg-hover': index === selectedItemIndex }"
            @click="chooseMenuItem(item)"
          />
        </div>
      </LscSheet>
    </LscMenu>

    <div class="mt-2 text-body-2">Test formula: {{ formulaString }}</div>
  </div>
</template>

<style scoped>
.formula-editor {
  @apply w-full rounded-md border border-separator hover:border-form-hover;
}

.formula-editor :deep(.ProseMirror) {
  min-height: 6.25rem;
  padding: 0.5rem;
  outline: none;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.formula-editor :deep(.ProseMirror p) {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.25rem;
  min-height: 1.75rem;
  line-height: 1.75rem;
}

/* Style for text nodes to match chip height */
.formula-editor :deep(.ProseMirror p > *) {
  min-height: 1.75rem;
  display: inline-flex;
  align-items: center;
}

.formula-editor :deep(.formula-chip:focus) {
  outline: none !important;
  box-shadow: none !important;
}

.formula-editor :deep(.ProseMirror-focused) {
  @apply border-form-active;
}

.formula-editor :deep(.ProseMirror p.is-empty::before) {
  content: attr(data-placeholder);
  @apply text-subtle;
}

.formula-editor :deep(.ProseMirror-focused p.is-empty::before) {
  content: none;
}
</style>
